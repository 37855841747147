import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Github, Linkedin, Mail, Twitter, Youtube, Award, FileText, ArrowRight } from 'lucide-react';

const useIntersectionObserver = (options = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observerRef = useRef(null);
  const elementRef = useRef(null);

  const cleanupObserver = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  }, []);

  useEffect(() => {
    const currentElement = elementRef.current;
    
    if (!currentElement) return;

    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    observerRef.current.observe(currentElement);

    return () => {
      cleanupObserver();
    };
  }, [options, cleanupObserver]);

  return [elementRef, isIntersecting];
};

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState('about');
  const [hoveredProject, setHoveredProject] = useState(null);

  document.title = "Somshuvra Basu";

  const projects = [
    {
      name: "SpectraVis",
      description: "Hyperspectral Analysis Tool for scientific data visualization.",
      technologies: ["Python", "PyQt", "Spectral Analysis"],
      date: "Nov '24",
      github: "https://github.com/SomshuvraBasu/SpectraVis",
      details: [
        "Built a Python-based tool enabling interactive spectral analysis of complex hyperspectral data.",
        "Implemented the Spectral Angle Mapper (SAM) algorithm for precise spectral matching.",
        "Developed a GUI using PyQt for real-time spectral visualization and library management, facilitating seamless user interaction.",
        "Optimized algorithms to handle large datasets, significantly improving processing speed."
      ]
    },
    {
      name: "RadarSim",
      description: "Real-time Radar Physics Simulator for target tracking.",
      technologies: ["Python", "Pygame", "Radar Simulation"],
      date: "Sept '24",
      github: "https://github.com/SomshuvraBasu/RadarSim",
      details: [
        "Designed a radar simulation to track up to 100 dynamic targets with realistic physics effects.",
        "Integrated features such as Doppler shift for velocity estimation and signal attenuation based on path loss models.",
        "Built an interactive UI with Pygame to visualize radar sweeps and real-time target data, achieving 60 FPS performance.",
        "Reduced latency through optimized algorithms, enabling responsive and high-fidelity simulation outputs."
      ]
    },
    {
      name: "SahiCurrency",
      description: "Android app for currency recognition by the visually impaired.",
      technologies: ["Android", "TensorFlow Lite", "Voice Accessibility"],
      date: "Jun 2023",
      github: "https://github.com/SomshuvraBasu/SahiCurrency",
      details: [
        "Developed an app to recognize and announce Indian currency denominations using TensorFlow Lite.",
        "Enhanced accessibility with voice-over features and optimized recognition performance by 30%.",
        "Empowered visually impaired users with greater independence in financial interactions."
      ]
    },
    {
      name: "Melody4U [Music Recommendation Engine]",
      description: "Emotion-based music recommendation engine.",
      technologies: ["Deep Learning", "BERT", "APIs", "ELO Rating"],
      date: "Nov 2023",
      github: "https://github.com/SomshuvraBasu/Melody4U",
      details: [
        "Developed a music recommendation engine blending user preferences with listening history.",
        "Employed ELO rating and fine-tuned BERT for emotion-based and genre-specific recommendations.",
        "Achieved genre classification F1 score of 0.88 using spectral analysis and STFT techniques.",
        "Integrated YouTube Data API for delivering custom playlists seamlessly."
      ]
    },
    {
      name: "Implementation of CDMA in LiFi Communication",
      description: "Data transmission using CDMA over LiFi.",
      technologies: ["LiFi", "CDMA", "Optical Communication"],
      date: "Mar 2023 - Present",
      github: "https://github.com/SomshuvraBasu/LiFi-CDMA",
      details: [
        "Demonstrated data transmission using Code Division Multiple Access (CDMA) technology over Light Fidelity (LiFi).",
        "Utilized light as the transmission medium to address growing needs for fast and dependable data transfer.",
        "Highlighted CDMA's versatility across IoT, satellite communication, and high-speed internet access applications."
      ]
    },
    {
      name: "Cloud Monitoring of Single Phase AC Systems",
      description: "IoT-based monitoring and analysis of single-phase AC systems.",
      technologies: ["IoT", "Sensors", "Microcontrollers", "Power Electronics"],
      date: "Mar 2024 - Apr 2024",
      details: [
        "Integrated sensors to measure voltage, current, power, and energy consumption in real-time.",
        "Developed a cloud-based platform to remotely monitor and analyze electrical parameters.",
        "Provided insights for energy optimization, fault detection, and improved system reliability."
      ]
    },
    {
      name: "SongPlay Chrome Extension",
      description: "A pop-up song-video player to enhance brainstorming sessions.",
      technologies: ["Chrome Extension", "JavaScript", "YouTube API"],
      date: "2024",
      details: [
        "Developed a Chrome extension that provides a convenient pop-up player for playing songs from YouTube playlists.",
        "Designed an intuitive and user-friendly interface to enable seamless usage during brainstorming activities.",
        "Integrated with YouTube playlists for easy access to curated collections of songs.",
        "Added a random song selection feature for dynamic and creative playback experiences."
      ]
    },
    {
      name: "WeSecure",
      description: "Visitor management system for senior citizens.",
      technologies: ["OpenCV", "Google Sheets API", "Telegram API"],
      date: "Oct 2022 - Nov 2022",
      github: "https://github.com/SomshuvraBasu/WeSecure",
      details: [
        "Built a secure visitor management system featuring facial recognition using OpenCV.",
        "Automated visitor logs with Google Sheets API and implemented Telegram-based notifications.",
        "Provided enhanced security and peace of mind for senior citizens living alone."
      ]
    }
  ];
  
  const experiences = [
    {
      company: "Dhruva Space",
      role: "SWE Intern, Software & Firmware Division",
      period: "May 2024 - July 2024",
      highlights: [
        "Developed data processing software for aerial hyperspectral pushbroom sensor, optimizing performance for <5 ms exposures",
        "Implemented algorithms for time block extraction and inverse weighted interpolation, enhancing flight data processing pipelines by 30%",
        "Achieved 98% accuracy in spectral measurements through calibration and validation using ground truth data",
        "Utilized GDAL, Pymap3D, and Geopandas to reduce geospatial data processing time from 50 to 55 seconds via multiprocessing",
        "Delivered feature enhancements for a strategic defence client project"
      ]
    },
    {
      company: "Reliance Jio",
      role: "ML Intern, Jio AI-CoE",
      period: "May 2023 - Aug 2023",
      highlights: [
        "Optimized ML models with PySpark for large datasets, contributing to AutoML solutions for alternate credit score prediction",
        "Created interactive Power BI dashboards to visualize scaled ML model outcomes and factor interactions",
        "Applied SHAP algorithms for Root Cause Analysis, providing actionable insights"
      ]
    },
    {
      company: "Nexus Space Teams",
      role: "Team Lead (NexSat)",
      period: "Jul 2022 - Apr 2024",
      highlights: [
        "Led 8-member team to CanSat India National Finals",
        "Developed direct georeferencing technique for aerial imagery with 15.361 pixels RMS error",
        "Captured high-resolution imagery with 1m to 0.1m Ground Sample Distance",
        "Achieved 2.67 m/s controlled descent rate from 800m altitude",
        "Engineered experimental attitude control system with passive, spring-based fins"
      ]
    }
  ];

  const awards = [
    "IEEE GRSS Grant for InGARSS 2024",
    "National Finalist at CanSat India Competition",
    "3rd Position in 24-Hour Hackathon - MNIT Jaipur",
    "Ranked 2nd in Hack-A-Thon - Chandigarh Police & Infosys",
    "KVPY Fellow - IISc Bangalore",
    "NTSE Scholar - NCERT"
  ];

  const publications = [
    {
      title: "Direct Georeferencing of CanSat Aerial Imagery: Insights from IN-SPACe CanSat India Launch",
      journal: "IEEE India Geoscience and Remote Sensing Symposium",
      date: "Dec 2024",
      link: "https://somshuvrabasu.github.io/CanSatDirectGeoreferencing/",
      abstract: "CanSats, miniature satellite-like devices, are revolutionizing educational and research applications in space technology. This paper explores the novel use of Earth Observation (EO) payloads on CanSats for remote sensing and geospatial intelligence, focusing on direct georeferencing techniques. By integrating precise MEMS based IMU sensors, high-accuracy GNSS receivers, and OV2640-based image sensors, we demonstrate a method to accurately assign geographic locations to images without ground control points. Our approach leverages sensor dimensions, payload mounting details, and trajectory data to transform frames of reference, enabling precise image localization through translations and rotations. Data collected during the CanSat India finals launch of IIT Jodhpur's CanSat validates this method. The resulting georeferenced images showcase the potential of CanSats to advance remote sensing and geospatial intelligence, opening new avenues for cost-effective, high-resolution Earth observation."
    }
  ];

  const blogs = [
    {
      title: "Introducing SpectraVis: A Hyperspectral Analysis Tool",
      date: "Dec 2024",
      link: "https://www.linkedin.com/posts/somshuvra-basu_hyperspectralimaging-datascience-software-activity-7269572964591796224-g2ML?utm_source=share&utm_medium=member_desktop",
      description: "Learn how SpectraVis is helping hyperspectral data analysis with its intuitive interface and powerful spectral processing capabilities."
    }
  ];

  const AnimatedSection = ({ children, className = '' }) => {
    const [ref, isVisible] = useIntersectionObserver({
      threshold: 0.1
    });

    return (
      <div 
        ref={ref} 
        className={`
          ${className} 
          transform transition-all duration-700 ease-out
          ${isVisible 
            ? 'opacity-100 translate-y-0' 
            : 'opacity-0 translate-y-10'
          }
        `}
      >
        {children}
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-br from-[#0f172a] to-[#1e293b] text-white min-h-screen font-sans antialiased">
      {/* Navigation */}
      <nav className="fixed top-0 w-full bg-[#1e293b]/80 backdrop-blur-xl shadow-lg z-50">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <div className="flex items-center space-x-3">
            <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
              <span className="text-white font-bold">SB</span>
            </div>
            <h1 className="text-2xl font-bold tracking-tight">Somshuvra Basu</h1>
          </div>
          <div className="flex space-x-6 bg-[#334155] rounded-full px-6 py-2">
            {['about', 'experience', 'projects', 'publications', 'awards', 'blogs'].map(section => (
              <button 
                key={section}
                onClick={() => setActiveSection(section)}
                className={`
                  capitalize text-sm font-medium tracking-wide 
                  px-3 py-1 rounded-full transition-all duration-300
                  ${activeSection === section 
                    ? 'bg-blue-600 text-white' 
                    : 'text-gray-300 hover:text-white hover:bg-[#475569]'}
                `}
              >
                {section}
              </button>
            ))}
          </div>
        </div>
      </nav>

      {/* Header */}
      <AnimatedSection className="pt-24 container mx-auto px-6 text-center relative">
        <div className="absolute inset-0 bg-blue-500/10 blur-3xl -z-10"></div>
        <h1 className="text-6xl font-black mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
          Somshuvra Basu
        </h1>
        <p className="text-xl text-gray-300 mb-6 max-w-2xl mx-auto">
          Space & Software | B.Tech IIT Jodhpur
        </p>
        <div className="flex justify-center space-x-4 mb-8">
          {[
            { Icon: Mail, href: "mailto:somshuvra.basu@gmail.com", label: "Email" },
            { Icon: Github, href: "https://github.com/SomshuvraBasu", label: "GitHub" },
            { Icon: Linkedin, href: "https://linkedin.com/in/somshuvra-basu", label: "LinkedIn" },
            { Icon: Twitter, href: "https://twitter.com/AwSomBasu", label: "X" },
            { Icon: Youtube, href: "https://www.youtube.com/@awsom", label: "YouTube" }
          ].map(({ Icon, href, label }) => (
            <a 
              key={label}
              href={href} 
              target="_blank" 
              rel="noopener noreferrer"
              className="
                p-3 bg-[#334155] rounded-full 
                hover:bg-blue-600 transition-all duration-300 
                transform hover:-translate-y-1 hover:scale-110
              "
              aria-label={label}
            >
              <Icon size={24} className="text-gray-300 hover:text-white" />
            </a>
          ))}
        </div>
      </AnimatedSection>

      {/* Experience Section */}
      {activeSection === 'experience' && (
        <AnimatedSection className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold mb-8 text-center">Experience</h2>
          {experiences.map((exp, index) => (
            <div 
              key={index} 
              className="bg-gray-800 rounded-lg p-6 mb-6 hover:scale-[1.02] transition-transform duration-300 text-justify"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-semibold text-blue-400">{exp.company}</h3>
                <span className="text-gray-400">{exp.period}</span>
              </div>
              <p className="text-xl mb-4">{exp.role}</p>
              <ul className="list-disc list-inside text-gray-300">
                {exp.highlights.map((highlight, i) => (
                  <li key={i} className="mb-2">{highlight}</li>
                ))}
              </ul>
            </div>
          ))}
        </AnimatedSection>
      )}

      {/* Projects Section */}
      {activeSection === 'projects' && (
        <AnimatedSection className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold mb-8 text-center">Projects</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {projects.map((project, index) => (
              <div 
                key={index} 
                className="bg-gray-800 rounded-lg p-6 hover:scale-105 transition-transform duration-300 group text-justify"
              >
                <h3 className="text-2xl font-semibold mb-4 text-blue-400">{project.name}</h3>
                <p className="text-gray-300 mb-4">{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map(tech => (
                    <span 
                      key={tech} 
                      className="bg-blue-900/50 text-blue-300 px-2 py-1 rounded-full text-sm"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <ul className="list-disc list-inside text-gray-400 mb-4">
                  {project.details.map((detail, i) => (
                    <li key={i} className="mb-2">{detail}</li>
                  ))}
                </ul>
                <a 
                  href={project.github} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center text-blue-400 hover:underline"
                  aria-label={`${project.name} GitHub Repository`}
                >
                  <Github className="mr-2" size={20} /> View on GitHub
                </a>
              </div>
            ))}
          </div>
        </AnimatedSection>
      )}

      {/* Awards Section */}
      {activeSection === 'awards' && (
        <AnimatedSection className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold mb-8 text-center">Honors & Awards</h2>
          <div className="grid md:grid-cols-2 gap-4">
            {awards.map((award, index) => (
              <div 
                key={index} 
                className="bg-gray-800 rounded-lg p-4 flex items-center hover:scale-105 transition-transform duration-300"
              >
                <Award className="mr-4 text-blue-400" size={24} />
                <span className="text-gray-300">{award}</span>
              </div>
            ))}
          </div>
        </AnimatedSection>
      )}
      {/* Publications Section */}
      {activeSection === 'publications' && (
        <AnimatedSection className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold mb-8 text-center">Publications</h2>
          {publications.length > 0 ? (
            publications.map((pub, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-6 mb-6 hover:scale-[1.02] transition-transform duration-300 text-justify">
                <h3 className="text-2xl font-semibold text-blue-400 mb-2">{pub.title}</h3>
                <p className="text-gray-400 mb-2">{pub.journal} - {pub.date}</p>
                <p className="text-gray-300 mb-4">{pub.abstract}</p>
                <a 
                  href={pub.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline"
                >
                  View Full Paper
                </a>
              </div>
            ))
          ) : (
            <p className="text-gray-400 text-center">No publications available yet.</p>
          )}
        </AnimatedSection>
      )}

      {/* Blog Section */}
      {activeSection === 'blogs' && (
        <AnimatedSection className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold mb-8 text-center">Blogs</h2>
          {blogs.length > 0 ? (
            blogs.map((blog, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-6 mb-6 hover:scale-[1.02] transition-transform duration-300 text-justify">
                <h3 className="text-2xl font-semibold text-blue-400 mb-2">{blog.title}</h3>
                <p className="text-gray-400 mb-2">{blog.date}</p>
                <p className="text-gray-300 mb-4">{blog.description}</p>
                <a 
                  href={blog.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline"
                >
                  Read Blog
                </a>
              </div>
            ))
          ) : (
            <p className="text-gray-400 text-center">No blogs available yet.</p>
          )}
        </AnimatedSection>
      )}

      {activeSection === 'about' && (
        <AnimatedSection className="container mx-auto px-4 py-12">
          <div className="max-w-3xl mx-auto bg-gray-800 rounded-lg p-8">
            <h2 className="text-3xl font-bold mb-6 text-center text-blue-400">About Me</h2>
            <div className="text-gray-300 space-y-4 text-justify">
              <p>
                Hi, I’m a final-year Electrical Engineering student at IIT Jodhpur with a passion for innovation—whether it’s through technology, teamwork, or new ideas. 
                I thrive at the intersection of engineering, research, and innovation.
              </p>
              <p>
                My journey has taken me from developing cutting-edge hyperspectral processing software at Dhruva Space to contributing to AI-driven solutions with Reliance Jio’s XAI team. 
                Along the way, I’ve had the privilege of leading my college’s CanSat and High Powered Rocketry teams, where I learned the value of collaboration and perseverance in 
                turning ambitious ideas into reality.
              </p>
              <p>
                Curiosity drives me. I’m always eager to learn, whether it’s diving deep into geospatial algorithms or tackling the complexities of aerospace systems. 
                For me, growth isn’t just about technical accomplishments—it’s about connecting with people, sharing knowledge, and building something meaningful together. 
                Let’s create something extraordinary.
              </p>
              <div className="flex items-center space-x-4">
                <FileText className="text-blue-400" size={24} />
                <a 
                  href="https://drive.google.com/file/d/1a-TnFDeDwWX8nxK3wu8kDJIjUs2zIwtq/view?usp=sharing" 
                  className="text-blue-300 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Download Resume"
                >
                  Download Full Resume
                </a>
              </div>
            </div>
          </div>
        </AnimatedSection>
      )}

    </div>
  );
};

export default Portfolio;